
import { computed, ComputedRef, defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useShowExposes, useDeleteShowExposes } from '@/composables/api';
import {
  toggleShowExposes,
  toggleShowExpose,
  ShowExposeSortKey,
  ScheduleStatus,
  DELETED_SUCCESSFULLY_TEXT,
  ResponseError
} from '@/services/api';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { usePage } from '@/composables/page';
import { formatLocalTime } from '@/utils/format-time';
import { useSort } from '@/composables';
import { SORT_MAP } from '../constants';
import { invert } from 'lodash';

export default defineComponent({
  components: {
    Filter,
    permissionUnits
  },
  setup() {
    const { setPageQuery, page, pageSize } = usePage();
    const { sortKey, sortOrder } = useSort<ShowExposeSortKey>();
    const status = ref<ScheduleStatus>();
    const toggleAllStatus = ref(false);

    const statusOptions = {
      prepared: '排程中',
      actived: '進行中',
      expired: '已過期'
    };
    const statusSelectOptions = [
      { label: '排程中', value: 'prepared' },
      { label: '進行中', value: 'actived' },
      { label: '已過期', value: 'expired' }
    ];

    const { data, isLoading, isFetching, refetch } = useShowExposes({ page, pageSize, status, sort: sortOrder, sortKey });
    const { isLoading: isDeleting, mutate } = useDeleteShowExposes();

    const handleFilterChange = (event: FilterEvent) => {
      const key = event[0].toUpperCase();
      status.value = ScheduleStatus[key];
    };

    const handleSortChange = ({ prop, order }) => {
      sortKey.value = prop || undefined;
      sortOrder.value = SORT_MAP[order];
    };

    const handleAllExposeChange = async() => {
      try {
        const data = { status: status.value, enable: toggleAllStatus.value };
        await toggleShowExposes(data);
        refetch.value();
      } catch (err) {
        ElMessage.error((err as ResponseError).response?.data.error.message || '伺服器錯誤');
      }
    };

    const handleExposeChange = async(id) => {
      try {
        await toggleShowExpose(id);
      } catch (err) {
        ElMessage.error((err as ResponseError).response?.data.error.message || '伺服器錯誤');
      }
      refetch.value();
    };

    const currentDeletedIndex = ref<number>(); // 刪除按鈕刪除中的動畫flag
    const deleteShowExpose = (id: number, index: number) => {
      currentDeletedIndex.value = index;

      const onSuccess = () => {
        refetch.value();
        ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
      };

      const onError = (error: ResponseError) => {
        ElMessage.error(error.response?.data?.error?.message);
      };

      ElMessageBox.confirm('是否確認要刪除？', '警告',
        {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => mutate(id, { onSuccess, onError }));
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: statusSelectOptions
        }
      ];
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeleting,
      filterOptions,
      statusOptions,
      currentDeletedIndex,
      toggleAllStatus,
      setPageQuery,
      handleSortChange,
      handleFilterChange,
      handleAllExposeChange,
      handleExposeChange,
      deleteShowExpose,
      formatLocalTime,
      defaultSort: computed(() => ({
        prop: sortKey.value,
        order: invert(SORT_MAP)[sortOrder.value]
      }))
    };
  }
});
