
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';
import useFormErrors from '@/utils/validate';
import { Rules } from 'async-validator';
import { useCreateShowExposes, useUpdateShowExposes, useShowExpose } from '@/composables/api';
import { ShowExposesForm } from '@/interfaces';
import { CREATED_SUCCESSFULLY_TEXT, UPDATED_SUCCESSFULLY_TEXT, ResponseError } from '@/services/api';

const DEFAULT_FORM_VALUES: ShowExposesForm = {
  dateArr: '',
  startTime: '',
  endTime: '',
  enable: false
};

const rules: Rules = {
  dateArr: { required: true, message: '此為必填欄位' },
  startTime: { required: true, message: '此為必填欄位' },
  endTime: { required: true, message: '此為必填欄位' }
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const id = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreating, mutate: create } = useCreateShowExposes();
    const { isLoading: isUpdating, mutate: update } = useUpdateShowExposes();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useShowExpose(id, {
      enabled: isEdit.value, // 編輯表單時, 會傳入props isEdit: true, 觸發enabled去打api拿資料
      refetchOnWindowFocus: !isEdit.value
    });

    watch(dataUpdatedAt, () => {
      const { startDate, endDate, startTime, endTime, enable } = data.value.data;
      const dateArr = [startDate, endDate];

      formValues.value = { dateArr, startTime, endTime, enable };
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      if (!form) return;

      form.validate((valid: boolean) => {
        if (!valid) return;

        const [startDate, endDate] = formValues.value.dateArr;
        const { startTime, endTime, enable } = formValues.value;
        const data = { startDate, endDate, startTime, endTime, enable };

        const onError = (error: ResponseError) => {
          const message = error?.response?.data?.error?.message || '伺服器錯誤';
          ElMessage.error({ message, type: 'error' });
          formErrors.value = message;
        };

        const onSuccess = (message) => {
          ElMessage.success(message);

          formValues.value = DEFAULT_FORM_VALUES;
          router.push('/systems/show-exposes');
        };

        // 編輯表單phase
        if (isEdit.value) {
          update(
            { id, data },
            { onSuccess: () => onSuccess(UPDATED_SUCCESSFULLY_TEXT), onError }
          );
        } else {
        // 新增表單phase
          create(
            { data },
            { onSuccess: () => onSuccess(CREATED_SUCCESSFULLY_TEXT), onError }
          );
        }
      });
    };

    return {
      formValues,
      formRef,
      rules,
      isCreating,
      isUpdating,
      formErrors,
      submitForm,
      bindFormItemError
    };
  }
});
